<template>
  <div class="front_container">
    <!-- 头部背景部分 -->
    <div class="front_top">
      <div class="title">LD学员</div>
      <div class="advantage">简单易用、注重核心功能、更贴近当前学员的年龄分层</div>
      <div class="details">
        无需下载安装，同时也能让学员在日常的工作生活中随时随地接入到学车的状态。以更简单易用的形式，确保每个年龄层的学员都能轻松上手，适合当前的不同年龄层的学员，同时实现学员与驾校的信息互通，让学员的每个进度与需求都能让驾校知晓。
      </div>
    </div>
    <!-- 中间介绍部分 -->
    <div class="front_main">
      <!-- 产品介绍 -->
      <div class="introduce">
        <div class="title">产品介绍</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="15">
            <div class="introduce_text">
              <div>
                <span style="color:#1684fc">LD学员</span>,一个真正意义的学车工具。集<span style="color:#1684fc">科一、四理论</span>刷题、<span
                  style="color:#1684fc">科二/三线上学习、练车考试登记预约、
                  浏览驾考相关资讯</span>,等众多功能。以更合理的会员定价向学员提供丰富的当地考场相关内容。从专业的角度向学员提供适合个人情况的驾考用品.
              </div>
            </div>
          </el-col>
          <el-col :span="9">
            <div class="pic">
              <img :src="introduce_pic" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 核心功能 -->
      <div class="main_function">
        <div class="title">核心功能</div>
        <div class="language">FUNCTION</div>
        <el-row type="flex" justify="center" align="center">
          <el-col :span="12">
            <div class="pic">
              <img :src="function_pic" alt="">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 驾考资讯 -->
              <div class="function_item">
                <div class="title">
                  驾考资讯
                </div>
                <div class="details_text">完全符合当地考场的驾考资讯，每一条都与学员息息相关，让学员轻松知晓一手资讯
                  确保学员合理安排每一科的考试计划
                  Information •••</div>
              </div>
              <!--  科二、三视频课程 -->
              <div class="function_item">
                <div class="title">
                  科二、三视频课程
                </div>
                <div class="details_text">结合当地的考试车辆、考试流程、考场设计、考场要求，以及教练的教学方式，给予教学视频明确的定位
                  全面准确的向学员呈现最优的学车教材。让学员能更清晰的了解实操考试.
                  Video teaching material •••</div>
              </div>
            </div>

          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="center" style="margin-top:30px">
          <el-col :span="12">
            <div class="function_text_box">
              <!-- 科二、三知识点测试 -->
              <div class="function_item" style="width:500px">
                <div class="title">
                  科二、三视频课程
                </div>
                <div class="details_text">通过学员熟悉的方式，为学员提供一个更全面多样性的学习方式
                  让学员能完全理解教练的教学内容
                  aTheory test •••</div>
              </div>
              <!-- 同步驾校的数据 -->
              <div class="function_item" style="width:500px">
                <div class="title">
                  同步驾校的数据
                </div>
                <div class="details_text">练车、考试、个人信息、通知、学习进度、考试进度、驾校教练信息
                  能实时与所报名的驾校进行数据互动，更能体现出工具的的定位
                  Data Synchronization •••</div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="pic">
              <img :src="function_pic" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 产品价值 -->
      <div class="product_value">
        <div class="title">产品价值</div>
        <div class="language">VALUE</div>
        <el-row type="flex" justify="center" align="center" style="margin-bottom:50px">
          <el-col :span="10">
            <div class="left">
              <div class="value_pic">
                <img :src="value1_pic" alt="">
              </div>
              <div class="title">将学车软件，回归到一个工具的定位</div>
              <div class="text">一个工具就应该有一个正真体现价值的定位</div>
            </div>

          </el-col>
          <el-col :span="14">
            <div class="right">
              <div class="text_list">
                <div>• 科一理论会员19.8元/60天</div>
                <div>• 科二教学视频免费、考场实景19.8元/60天</div>
                <div>• 科三教学视频免费、考场线路29.8元/60天</div>
                <div>• 科四理论会员19.8元/60天</div>
                <div>• 驾考全套会员88元/360天</div>
              </div>
              <div class="value_pic">
                <img :src="value11_pic" alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 底部 -->
    <div class="front_footer">
      <BottomFooter></BottomFooter>
    </div>
  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  export default {
    name: 'WorkspaceJsonFrontpc',
    components: {
      BottomFooter
    },

    data() {
      return {
        introduce_pic: require('.././assets/introduce.png'),
        function_pic: require('.././assets/function.png'),
        value1_pic: require('.././assets/value1.png'),
        value11_pic: require('.././assets/value1-1.png'),
        value2_pic: require('.././assets/value2.png'),
        value22_pic: require('.././assets/value2-2.png'),
        value3_pic: require('.././assets/value3.png'),
        value33_pic: require('.././assets/value3-3.png'),

      };
    },

    mounted() {

    },

    methods: {

    },
  };

</script>

<style lang="scss" scoped>
  .front_container {
    width: 100%;
    height: 100%;

    .front_top {
      width: 100%;
      height: 300px;
      background: url(".././assets/front_bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
      ;
      background-size: 100% 100%;
      color: #ffffff;
      box-sizing: border-box;
      padding: 50px 0;

      .title {
        font-size: 40px;
        margin-bottom: 20px;
      }

      .advantage {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .details {
        width: 80%;
        margin: 0 auto;
        font-size: 15px;
        text-align: left;
      }
    }

    .front_main {
      box-sizing: border-box;
      padding: 30px 40px;
      border-bottom: 1px solid #ccc;

      .introduce,
      .main_function,
      .product_value {
        width: 100%;

        .title {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .language {
          color: rgba(233, 233, 233, 1);
          margin-bottom: 20px;
        }

        .introduce_text {
          width: 80%;
          height: 100%;
          text-align: left;
          line-height: 40px;
          display: flex;
          align-items: center;
        }

        .pic {
          width: 500px;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .function_text_box {
          text-align: left;
          width: 600px;

          .function_item {
            margin-bottom: 30px;

            .title {
              font-size: 18px;
              font-weight: normal;
              margin-bottom: 10px;
            }

            .details_text {
              line-height: 30px;
              color: rgba(145, 145, 145, 1);
            }
          }

        }

        .left {
          width: 100%;
          box-sizing: border-box;
          padding-left: 60px;
          text-align: left;

          .value_pic {
            width: 60px;
            height: 50px;
            margin-bottom: 20px;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
          }

          .text {
            color: rgba(145, 145, 145, 1);
          }
        }

        .right {
          width: 600px;
          height: 180px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          position: relative;

          .text_list {
            text-align: left;
            margin-left: 40px;
          }

          .value_pic {
            width: 150px;
            height: 150px;
            position: absolute;
            right: -60px;
            bottom: -10px;
          }
        }


      }
    }

    .front_footer {
      height: 100px !important;
    }

  }

</style>
